import bgVideo from 'assets/videos/113364-697718054_medium.mp4';
import cbs from 'assets/images/logo.svg';

function App() {
  return (
    <div className='bg-black relative h-screen flex items-center justify-center'>
      <div className='z-10 flex justify-center items-center flex-col'>
        <img src={cbs} alt='cbs' className='w-[120px]' />
        <p className='text-white font-extralight mt-8'>
          Our new site is under construction
        </p>
        <a
          href='mailto:hello@creativebusinesssolutions.uk'
          className='relative inline-flex items-center justify-start inline-block px-5 py-3 overflow-hidden font-light rounded-full group mt-4'
        >
          <span className='w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]'></span>
          <span className='absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-8'></span>
          <span className='relative w-full text-sm text-left text-white transition-colors duration-200 ease-in-out group-hover:text-gray-900'>
            Contact us
          </span>
          <span className='absolute inset-0 border border-white rounded-full'></span>
        </a>
      </div>
      <video
        autoPlay
        loop
        muted
        className='absolute top-0 object-cover h-screen opacity-10'
      >
        <source src={bgVideo}></source>
      </video>
    </div>
  );
}

export default App;
